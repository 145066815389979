.gjs-btn-prim {                                     /*Dedrive plugin button*/
  color: #fff;
  background-color: rgba(59, 134, 214, 0.842);
  border-radius: 2px;
  padding: 3px 6px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  font-family: 'Saira', sans-serif;
  font-style:inherit;
}
.gjs-btn-prim:active {                              /*Dedrive plugin button click after*/
  background-color: rgba(24, 23, 23, 0.778);
}
div.gjs-devices-c{                                  /*hidden the bug with devices label*/
  display:none;
}
div.gjs-cv-canvas{                                  /*the canvas top line color*/
  border-top: solid;
  border-color: #0e0e0fa4;
}
.gjs-block {                                        /*Editor blockManager's blocks style*/
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  width: 28%;
  min-width: 28%;
  padding: 0.5em;
  box-sizing: border-box;
  min-height: 80px;
  cursor: all-scroll;
  font-size: 11px;
  font-weight: lighter;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(105, 103, 103, 0.949);
  border-radius: 3px;
  margin: 10px 2.5% 5px;
  box-shadow: 0 1px 0 0 rgba(156, 153, 153, 0.15);
  transition: all .2s ease 0s;
  transition-property: box-shadow,color;
}

.gjs-one-bg {       /*Editor full background color*/                              
  background-color: #181a1b;
}

.gjs-two-color { /*Editor all font color*/
  color: #ffffff;
}
.gjs-four-color { /*Editor panal i-con color after click the i-con*/
  color: #2284ec;
}
.gjs-cv-canvas {                               /*canvas's width  e.g .gjs-cv-canvas width = 75% .gjs-pn-options .gjs-pn-views .gjs-pn-views-container set 25% ,75% + 25% = 100% */
  background-color: rgba(0,0,0,.15);
  box-sizing: border-box;
  width: 75%;
  height: calc(100% - 40px);
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 40px;
}
.gjs-pn-options {
  right: 25%;
  top: 0;
}
.gjs-pn-views {
  border-bottom: 2px solid rgba(0,0,0,.2);
  right: 0;
  width: 25%;
  z-index: 4;
}
.gjs-pn-views-container {
  height: 100%;
  padding: 42px 0 0;
  right: 0;
  width: 25%;
  overflow: auto;
  box-shadow: 0 0 5px rgb(0 0 0 / 20%);
}
div.gjs-mdl-dialog.gjs-one-bg.gjs-two-color{
  top:4%;
}
div.gjs-export-dl{
  display: none;
}
/* label.gjs-radio-item-label{
  
} */
label.gjs-radio-item-label {
  flex-shrink: 0;
  cursor: pointer;
  text-indent: 0px;
  width: 100%;
  height: 100%;
  background: rgb(190, 105, 30);
  display: block;
  border-radius: 0px;
  position: relative;
}
label.gjs-radio-item-label::after{
  display: none;
}