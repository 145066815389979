@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  [data-theme="dark"] {
    --bg-main: #151718;
    --bg-sec: #181a1b;
    --main-border-color: #323438;
    --search-border: #373841;
    --gradient-trans: #181a1b00;
    --sec: #1b1e1f;
    --con-white: #1d2122;
    --title-color: #a29a8e;
    --item-text: #a29a8e;
    --text-main: #32cbae;
    --text-main-highlight: #1c947e;
    --green: #228f01;
    --border-gray: #2d2f31;
    --scrollbar-color: #2d2f31;
    --bg-gray: #333739;
    --item-border-gray: #232627;
    --create-border-gray: #232627;
    --logo-white: #b6d0db;
    --title-white: #b6d0db;
    --hover-color: #042a23;
    --line-color: #323638;
    --bucket-info-color: #1b1e1f;
    --bucket-info-text-color: #9fa1a6;
    --bucket-info-border-color: #363b3d;
    --user-color: #afa89d;
    --red: #963024;
    --setting-bg-gray: #212425;
    --setting-line-color: rgb(53, 58, 60);
    --bg-red: #510404;
    --text-red: #d76557;
    --item-name-text-color: #9e9589;
    --billing-option-border: rgb(53, 58, 60);
    --option-border-color: rgb(54, 59, 61);
    --option-text-color: rgb(197, 207, 221);
    --ios-theme: #000;
    --filter: invert(0);
    --icon-blue-color: #459ff6;
    --icon-bg-red: #410d04;
    --icon-border-red: #7d291e;
    --icon-bg-blue: #1e2122;
    --add-stamp-hover-bg: #242729;
    --stamp-bg: rgb(24, 26, 27);
    --billing-option-border-color: #1c947e;
    --billing-option-hover-border-color: #25c0a3;
    --error-red: #812319;
    --popup-shadow: none;
    --box-shadow: #2d2f31 0px 0px 0px 1px;
    --user-arrow-color: #f7f7f7;
    --quick-text-sec: #9e9589;
    --bg-gradient: linear-gradient(-45deg, rgb(41, 42, 43) 0%, rgb(25, 28, 28) 25%, rgb(19, 21, 21) 51%, rgb(23, 25, 26) 100%);
    --border-gray-sec: #2b2e30;
    --bg-chunk: #a54d03;
    --gradient-bg: linear-gradient(to right, rgb(27, 29, 30) 8%, rgb(30, 33, 34) 38%, rgb(27, 29, 30) 54%);
    --main-box-shadow: #1d2020;
    --deep-blue: #14466d;
    --checkbox-border-color: #424242;
  }

  [data-theme="light"] {
    --bg-sec: #f6f6f9;
    --gradient-trans: #00000000;
    --main-border-color: #f1f1f1;
    --sec: #f9f9f9;
    --con-white: #f4f6f8;
    --title-color: #888e99;
    --item-text: #9fa1a6;
    --text-main: #3e84ec;
    --border-gray: #ececec;
    --bg-gray: #f5f5f5;
    --item-border-gray: #ebeff5b2;
    --create-border-gray: #eeeeee;
    --logo-white: #303030;
    --title-white: #2f353f;
    --hover-color: #e7f3ff;
    --line-color: #f9f9f9;
    --bucket-info-color: #f9f9f9;
    --bucket-info-text-color: #4b5768;
    --bucket-info-border-color: rgb(229, 231, 235);
    --user-color: #707070;
    --red: #d4594a;
    --setting-bg-gray: #efefef;
    --setting-line-color: #ededed;
    --setting-title-color: #232839;
    --bg-red: rgb(252, 200, 200);
    --text-red: #d4594a;
    --button-blue: #2f69fe;
    --border-color: #e3e3e3;
    --item-name-text-color: rgb(107 114 128);
    --billing-option-border: #ededed;
    --option-border-color: rgb(229, 231, 235);
    --option-text-color: rgb(148, 151, 155);
    --ios-theme: #fff;
    --filter: invert(50%) sepia(100%) saturate(1129%) hue-rotate(194deg) brightness(93%) contrast(88%);
    --icon-blue-color: #468af8;
    --icon-bg-red: #feefed;
    --icon-border-red: #d4594a;
    --icon-bg-blue: #ecf3fc;
    --add-stamp-hover-bg: #d6e6fd;
    --stamp-bg: #ffffff;
    --billing-option-border-color: rgb(219 234 254);
    --billing-option-hover-border-color: rgb(147 197 253);
    --error-red: rgb(243, 177, 177);
    --popup-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    --bg-main: #ffffff;
    --box-shadow: rgba(42, 46, 66, 0.2) 0px 3px 12px -6px;
    --user-arrow-color: #aaaaaa;
    --quick-text-sec: #676c84;
    --bg-gradient: linear-gradient(-45deg, rgb(221, 232, 255) 0%, rgb(235, 243, 253) 25%, rgb(250, 250, 253) 51%, rgb(240, 248, 255) 100%);
    --border-gray-sec: #e8ecf0;
    --bg-chunk: rgb(251 146 60);
    --gradient-bg: linear-gradient(to right, #f5f5f5 8%, #e9e9e9 38%, #f5f5f5 54%);
    --main-box-shadow: #f6f6f6;
    --deep-blue: #14466d;
    --checkbox-border-color: #e6e6e6;
    --scrollbar-color: #17a0ea;
    --green: #72c376;
  }
}

::-webkit-scrollbar {
  width: 24px;
  height: 24px;
  border-radius: 0.75;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-gradient-to-b to-[#a6f3e5] from-[#32cbae];
  // background: var(--scrollbar-color);
  border: 8px solid transparent;
  background-clip: padding-box;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  width: 8px;
  background-color: var(--icon-bg-blue);
  border-radius: 2rem;
  border: 8px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@layer components {
  .text-main-highlight {
    color: var(--text-main-highlight);
  }
  .pb-mobile {
    padding-bottom: calc(1.25rem + 64px);
  }
  .box-shadow,
  .main-box-shadow {
    box-shadow: var(--box-shadow);
  }
  // .main-box-shadow {
  //   box-shadow: rgba(42, 46, 66, 0.2) 0px 3px 12px -6px;
  // }

  .landing-text-animation {
    animation: landing-text-fadein 8s ease-in-out infinite;
  }

  .upload-website-option-wrapper {
    @apply absolute top-0 z-[99] h-[44px] w-[calc(169px+0.25rem)] bg-gradient-to-r from-sky-500 to-indigo-500 dark:from-sky-800 dark:to-indigo-800;
    &[data-option="true"] {
      @apply left-0 rounded-l-md;
      foo: bar;
    }
    &[data-option="false"] {
      @apply left-[calc(169px+0.25rem)] rounded-r-md;
      foo: bar;
    }
    transition: all 0.25s;
  }
  .upload-website-option {
    @apply title-white z-[100] flex-1 cursor-pointer rounded-md py-1.5 text-center font-medium;
    foo: bar;
  }
  .setting-input {
    border-color: var(--setting-line-color);
    background: var(--bg-sec);
    color: var(--title-white);

    &:focus {
      @apply border-[#5091f3] dark:border-[#32cbae];
      foo: bar;
    }

    &.add {
      background: var(--bg-main);
    }
  }
  .setting-input-error {
    border-color: var(--error-red);
    background: var(--bg-sec);
    color: var(--title-white);

    &:focus {
      @apply border-red-600;
      foo: bar;
    }

    &.add {
      background: var(--bg-main);
    }
  }

  .bucket-info-text-color {
    @apply text-[#424c5c] dark:text-white;
    foo: bar;
  }

  .bg-main {
    background-color: var(--bg-main);
  }

  .main-theme {
    @apply bg-white dark:bg-[#111315];
    foo: bar;
  }

  .btn-hover {
    transform: translateY(0);
    transition: all 0.2s;
    &:hover {
      transform: translateY(-5px);
    }
  }

  .stamp-card {
    @apply bg-main relative w-[235px] shrink-0 cursor-pointer rounded-xl border p-4;
    foo: bar;
  }

  .skeleton {
    //@apply bg-[#f2f2f6] rounded-lg;
    @apply relative rounded-lg bg-[#f2f2f6] dark:bg-[#212324];
    // opacity: 0;
    // transform: translateX(100%);
    // animation: skeleton-fade-in 0.5s forwards;
    foo: bar;
  }

  .loading-lg {
    &::after {
      content: "";
      @apply rounded-lg bg-[#ececf065] dark:bg-[#272a2b];
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      animation: skeleton-loading 2.5s infinite;
    }
  }

  .loading-2xl {
    &::after {
      content: "";
      @apply rounded-2xl bg-[#ececf065] dark:bg-[#272a2bb6];
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      animation: skeleton-loading 2.5s infinite;
    }
  }

  .quick-access-skeleton {
    @apply h-[189px] w-[250px] shrink-0 rounded-[0.75rem] bg-[#f9f9fe] dark:bg-[#1a1c1d];
    foo: bar;
  }
}

//mixin
@mixin transform-center($transform) {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) $transform;
}

@mixin visible {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s;

  &[data-show="true"] {
    visibility: visible;
    opacity: 1;
  }
}

.popup-fadein {
  transform-origin: center;
  animation: popup-fadein 0.2s forwards;
}

.bg-chunk {
  background-color: var(--bg-chunk);
}

.quick-text-sec {
  color: var(--quick-text-sec);
}

.transition {
  transition: all 0.25s;
}

.transition-width {
  transition: width 0.5s;
}

.uploading-con {
  bottom: 0;
  transform: translateY(71px);
  transition: all 0.35s;
  pointer-events: none;
  opacity: 0;
  &.active,
  &.fold {
    pointer-events: auto;
    opacity: 1;
  }
  &.active {
    transform: translateY(0);
  }
  &.fold {
    transform: translateY(calc(100% - 66px));
  }
}

.uploading-item {
  animation: uploading-item-fadein 0.5s forwards;
}

.right-info-fade-in {
  animation: right-info-fade-in 0.45s forwards;
}

.border-gray-sec {
  border-color: var(--border-gray-sec);
}

.btn-delete {
  pointer-events: none;

  &.active {
    pointer-events: auto;
    opacity: 1;

    &:hover {
      border-color: var(--icon-border-red);
      color: #c82a32;
      background-color: var(--icon-bg-red);
    }
  }
}

.bg-deep-blue {
  background-color: var(--deep-blue);
}

.user-arrow-color {
  color: var(--user-arrow-color);
}

.bg-main {
  background-color: var(--bg-main);
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}
.loader {
  color: #fff;
  font-size: 6px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}

.billing-hover-color {
  &:hover {
    background-color: var(--hover-color);
  }
}

.search-input {
  &::placeholder {
    color: #929198;
    font-weight: 500;
  }
}

.billing-icon-color,
.setting-icon-color {
  color: var(--icon-blue-color);

  &.red {
    color: #c82a32;
  }
}

.billing-icon-bg-color,
.setting-icon-bg-color {
  background-color: var(--icon-bg-blue);

  &.red {
    background-color: var(--icon-bg-red);
  }
}

.left-nav-bg-wrapper,
.create-bucket-wrapper {
  @include visible;
}

.text-color {
  color: #8c8d92;
}

.transform-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo-img {
  filter: var(--filter);
  transition: all 0.2s;

  &.left {
    filter: var(--filter);
  }
}

.bg-gray {
  background: var(--bg-gray);
}

.billing-clip-con {
  height: 243px;
  transition: all 0.2s;

  &[data-clip="true"] {
    height: 0;
  }
}

.billing-icon-red {
  color: #c82a32;
}

.billing-usage-red {
  background-color: var(--red);
}

.billing-usage-blue {
  background-color: var(--text-main);
}

.billing-usage-green {
  background-color: var(--green);
}

.billing-usage-bg-red {
  background-color: var(--icon-bg-red);
}

.bg-upload-success {
  @apply bg-[#69c96c] dark:bg-[#17bb78];
  foo: bar;
}

.text-upload-success {
  @apply text-[#69c96c] dark:text-[#17bb78];
  foo: bar;
}

.billing-usage-bg-blue {
  background-color: var(--icon-bg-blue);
}

.billing-option-con {
  background: var(--bg-main);
}

.btn-billing {
  color: var(--item-text);
  border-color: var(--border-gray);

  &.active {
    color: var(--text-main);
    border-color: var(--text-main);
    font-weight: 500;
  }

  &:hover {
    color: var(--text-main);
    border-color: var(--text-main);
  }
}

// .item-name-text-color {
//   color: var(--item-name-text-color);
// }

.border-blue {
  border-color: var(--text-main);
}

.create-border-gray {
  border-color: var(--create-border-gray);

  &:focus {
    border-color: var(--text-main);
  }
}

.fade-in {
  animation: fade-in 0.35s forwards;
}

.fade-in-from-b {
  animation: fade-in-from-b 0.35s forwards;
}

@keyframes fade-in-from-b {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.option-hover-con {
  top: 50%;
  right: -100%;
  transform: translateY(-50%);
}

.thumb {
  transition: all 0.25s;
  opacity: 1;
  height: auto;
  max-height: 100px;
}

.border-color {
  border-color: var(--border-color);
}

.billing-option-border-color {
  border-color: var(--billing-option-border-color);

  &:hover {
    border-color: var(--billing-option-hover-border-color);
  }

  &.error {
    border-color: var(--error-red);
    animation: shake 0.55s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transition: all 0.3s;
  }
}

.billing-recharge-button {
  color: var(--title-color);
  width: auto;
  height: 10;
  border-radius: 25;

  &:hover {
    border-color: var(--border-color);
  }
}

.create-bucket-con {
  position: fixed;
  @include transform-center(scale(0.95));
  width: 400px;
  background: var(--bg-sec);
  transition: all 0.25s;
  opacity: 0;
  visibility: hidden;
  box-shadow: var(--popup-shadow);
  pointer-events: none;
  &.folder {
    min-width: 375px;
    width: fit-content;
  }
  &[data-show="true"] {
    top: 50%;
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
    pointer-events: auto;
  }

  &.website {
    &[data-option="true"] {
      height: 320px;
    }
    &[data-option="false"] {
      height: 732px;
    }
    transition: all 0.3s;
  }

  &.topup {
    width: 350px;
  }

  &.upload {
    min-width: 475px;
    width: fit-content;
  }

  &.create {
    min-width: 375px;
    width: 375px;
  }
}

.chunk {
  color: rgb(204, 204, 204);

  &[data-on="true"] {
    color: #32cbae;
  }
}

.button-blue {
  background: var(--button-blue);
  color: var(--bg-sec);
}

.form-control {
  height: 50px;
  width: 100% !important;
  border-color: var(--setting-line-color) !important;
  background-color: var(--bg-sec) !important;
  color: var(--title-white) !important;
  transition: all 0.2s !important;
}

.setting-con {
  position: fixed;
  @include transform-center(scale(1.1));
  opacity: 0;
  visibility: hidden;
  background-color: var(--bg-sec);
  z-index: 710;
  max-height: 65vh;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 999;
  box-shadow: var(--popup-shadow);
  // transform-origin: bottom center;

  &[data-show="true"] {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
  }
  @media (max-width: 768px) {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
    left: 100%;
    top: 0;
    transform: unset !important;
    transform-origin: unset;
    transition: all 0.35s;
    &[data-show="true"] {
      left: 0;
    }
  }
}

.gradient {
  animation-duration: 2.25s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: var(--gradient-bg);
  background-size: 3000px 640px;
  position: relative;
}

.scrollbar-hidden {
  &::-webkit-scrollbar {
    display: none;
  }
}

.billing-popup {
  transition: all 0.45s;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
}

.active-effect {
  transform: scale(1);
  transition: all 0.15s;

  &:active {
    transform: scale(0.75);
  }
}

.billing-popup-wrapper {
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.billing-popup-inner {
  transition: all 0.3s;
  opacity: 0;
}

.billing-new-option {
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
  }
}

.btn-billing-pay {
  // border: 2px solid rgb(96 165 250);
  transition: all 0.3s;

  // &:hover {
  //   background-color: #5f98ec;
  // }
}

.billing-stamp-con {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  }
}

.btn-add-stamp {
  transition: all 0.25s, background 0.2s;

  &:hover {
    background-color: var(--add-stamp-hover-bg);
    transform: scale(1.05);
  }
}

.btn-billing-pay {
  &:hover {
    > {
      .btn-billing-pay-icon {
        opacity: 1;
        transform: translate(0, -50%);
      }
    }
  }
}

.btn-billing-pay-icon {
  opacity: 0;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(20px, -50%);
  transition: all 0.25s;
}

.bg-stamp {
  background-color: var(--stamp-bg);
}

.btn-download {
  position: fixed;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%) translateY(10px);
  border-radius: 2rem;
  opacity: 0;
  background-color: var(--text-main);
  pointer-events: none;
  transition: all 0.2s;

  &[data-download="true"] {
    opacity: 1;
    pointer-events: all;
    transform: translateX(-50%) translateY(0px);
  }
}

.setting-con-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
  backdrop-filter: blur(5px);

  &[data-show="true"] {
    opacity: 1;
    visibility: visible;
  }
}

.special-label {
  display: none !important;
}

.switch-inner {
  transition: all 0.2s;
  position: absolute;
  left: 2px;
  top: 2px;
  width: 24px;
  aspect-ratio: 1 / 1;

  &[data-switch="true"] {
    left: 26px;
  }
}

.select-border-color {
  border-color: var(--setting-line-color);
}

.setting-title {
  color: var(--setting-title-color);
  font-weight: 500;
}

.setting-bg-gray {
  background: var(--setting-bg-gray);
}

.btn-red {
  background: var(--text-main);
}

.text-red {
  color: var(--red);
}

.user-color {
  color: var(--user-color);
}

.option-color {
  color: var(--option-text-color);
  border-color: var(--option-border-color);

  &:hover {
    color: var(--text-main);
  }

  &.main {
    &:hover {
      color: var(--option-text-color);
    }
  }
}

.bucket-info-border-color {
  border-color: var(--bucket-info-border-color);
  transition: none;
}

.bucket-info-color {
  background: var(--bucket-info-color);
}

.line-color {
  border-color: var(--line-color);
}

.bg-line-color {
  background: var(--bg-main);
}

.bg-sec {
  background: var(--bg-sec);
}

.text-main-color {
  color: var(--bg-sec);
}

.icon-color {
  color: var(--bg-sec);
}

.hover-color {
  background-color: var(--hover-color);
}

#switch {
  flex-shrink: 0;
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  flex-shrink: 0;
  cursor: pointer;
  text-indent: -9999px;
  width: 45px;
  height: 23px;
  background: rgb(179, 179, 179);
  display: block;
  border-radius: 100px;
  position: relative;
}

label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: #1a54e7;
}

input:checked + label:after {
  left: calc(100% - 1px);
  transform: translateX(-100%);
}

// label:active:after {
// 	width: 130px;
// }

.btn-edit {
  transform: translateX(15px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.15s;

  &[data-visible="true"] {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
}

.bucket-item {
  animation: slideIn 0.35s forwards;

  &[data-click="true"] {
    background-color: var(--hover-color);
    color: var(--text-main);
    > {
      .name {
        color: var(--text-main);
      }
    }
  }

  &:hover {
    background-color: var(--hover-color);

    &.item {
      // border-color: var(--blue);
    }

    &[data-del="true"] {
      background-color: var(--bg-red);
      color: var(--text-red);

      > {
        .name {
          color: var(--text-red);
        }
      }
    }

    > {
      .name {
        color: var(--text-main);
      }
    }
  }
}

.hover-color {
  background: var(--hover-color);
}

.con-white {
  background: var(--con-white);
}

.title-white {
  color: var(--title-white);
}

.sec {
  background: var(--sec);
  color: var(--title-white);
}

.text-main {
  color: var(--text-main);
}

.bg-blue {
  background-color: var(--text-main);
}

.text-green {
  color: var(--green);
}

.logo-white {
  color: var(--logo-white);
}

.border-gray {
  border-color: var(--border-gray);
}

.item-border-gray {
  border-color: var(--item-border-gray);
}

.hover-scale {
  transition: all 0.2s;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}

.dragText {
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.left-con {
  flex: 1;
  height: 100%;
  max-height: -webkit-fill-available;
  width: 215px;
  // border-right: 1px solid var(--border-gray);
  padding-left: 2.5rem;
  padding-top: 1.5rem;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  z-index: 399;
  background-color: var(--bg-main);
  display: flex;
  flex-direction: column;
}

.btn-expand {
  outline: none;
  display: none;
  position: fixed;
  bottom: 30px;
  left: 41px;
  width: 40px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  background-color: var(--text-main);
  transition: all 0.2s;
  place-items: center;
  z-index: 999;

  > {
    svg {
      transform: rotate(0deg);
    }
  }

  &[data-show="true"] {
    left: 195px;

    > {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}

.general-setting-con {
  padding-right: 1.5rem;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }

    padding-right: 0;
  }
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  // background: var(--bg-sec);
  position: relative;
  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
}

.bg {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  // background-image: var(--bg-gradient);
  background: var(--bg-sec);
  animation: AnimateBG 10s ease infinite;
}

.right-con {
  flex: 1;
  // height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.content-con {
  padding: 2rem;
  padding-top: 2rem;
  padding-bottom: 0;
  flex: 1;
  overflow-y: auto;
  border-top: 1px solid #32cbaf34;
  border-left: 1px solid #32cbaf34;
  border-top-left-radius: 20px;
  background: var(--bg-sec);

  &.billing {
    padding: 2.25rem 0;
  }
}

.top-nav {
  display: flex;
  position: relative;
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 0.85rem 2rem;
  justify-content: space-between;
  background: var(--bg-main);
  z-index: 900;
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 57px;
    padding: 0.6rem 1.25rem;
    border-bottom: 1px solid var(--border-gray);
    z-index: 799;
  }
}

.logo-con {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: 0px;
  overflow: hidden;
}

.logo {
  align-self: flex-end;
  background: #588edf;
  height: 2px;
  width: 8px;
  margin-bottom: 7px;
  margin-left: 2px;
}

.upload-con {
  position: absolute;
  @include transform-center("");
  width: 500px;
  height: 500px;
  background: var(--bg-sec);
  z-index: 200;
}

.custom-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.tab {
  position: absolute;
  border-radius: 9999px;
  top: -6px;
  right: -1px;
  height: 40px;
  width: 2px;
  background: var(--text-main);
  transition: all 0.2s;

  &[data-tab="pod"] {
    top: -6px;
  }

  &[data-tab="billing"] {
    top: 67px;
  }

  &[data-tab="builder"] {
    top: 141px;
  }

  // &[data-tab="/billing"] {
  //   display: none;
  // }
}

// .bg-gradient {
//   position: absolute;
//   top: 0;
//   right: 0;
//   height: calc(100% - 20px);
//   width: 25px;
//   z-index: 300;
//   transition: all 0.2s;
//   background: var(--background);
//   background: linear-gradient(
//     90deg,
//     var(--gradient-trans) 0%,
//     var(--background) 100%
//   );
// }

.btn-sort {
  @mixin sort {
    transform: rotateX(0deg);
    transition: all 0.25s;

    &[data-sort="true"] {
      transform: rotateX(180deg);
    }
  }

  &.name {
    &[data-type="name"] {
      @include sort;
    }
  }

  &.type {
    &[data-type="type"] {
      @include sort;
    }
  }

  &.lastModified {
    &[data-type="lastModified"] {
      @include sort;
    }
  }

  &.size {
    &[data-type="size"] {
      @include sort;
    }
  }

  &.status {
    &[data-type="status"] {
      @include sort;
    }
  }
}

.nav-option {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 45px;
  > {
    div {
      display: flex;
      gap: 1rem;
      // color: var(--gray);
      align-items: center;
      font-weight: 300;
      font-size: 14px;
    }
  }
}

input[type="checkbox"] {
  appearance: none;
  position: relative;
  width: 1.1rem;
  height: 1.1rem;
  aspect-ratio: 1 / 1;
  border: 2px solid var(--checkbox-border-color);
  border-radius: 0.25rem;
  padding: 0.15rem;
  background-color: var(--bg-main);
}

input[type="checkbox"]:checked {
  border-color: var(--text-main);
  background-color: var(--text-main);
}

input[type="checkbox"]::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 0.55rem;
  aspect-ratio: 1 / 1;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #fff;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: translate(-50%, -50%) scale(1);
}

.option-con {
  position: absolute;
  top: 70px;
  background: var(--bg-main);
  right: -0rem;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  width: 280px;
  transform: translateY(-0.5rem);
  z-index: 999 !important;
  transition: all 0.25s;
  padding {
    bottom: 0;
  }

  &[data-show="true"] {
    opacity: 1;
    visibility: visible;
    max-height: 500px;
    // padding-bottom: 1rem;
    transform: translateY(0);
  }
}

// .quick-access-con {
//   overflow: hidden;

//   &::after {
//     content: "";
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 3px;
//     background: var(--blue);
//   }

//   &.red {
//     &::after {
//       background: var(--red);
//     }
//   }
// }

.btn-container {
  scroll-behavior: smooth;
}

.upload-item-remove-ani {
  animation: upload-item-remove 0.5s forwards;
}

.btn-website{
  // margin-left: -8.25rem;
  opacity: 0;
  transition: all 0.25s;
  pointer-events: none;
  &.active {
    margin-right: 1rem;
    opacity: 1;
    pointer-events: auto;
  }
}

.btn-file {
  margin-left: -8.25rem;
  opacity: 0;
  transition: all 0.25s;
  pointer-events: none;
  &.active {
    margin-left: 1rem;
    opacity: 1;
    pointer-events: auto;
  }
}

.btn-file {
  margin-left: -7.5rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.icon-search {
  position: absolute;
  top: 51%;
  left: 0.75rem;
  transform: translateY(-50%);
  display: block;

  &.phone {
    display: none;
  }
}

.option-text-color {
  color: #9ea1a5;
}

hr {
  border-color: var(--border-gray);
  background-color: var(--border-gray);
}

.main-border-color {
  border-color: var(--border-gray);
}

.info-border {
  left: 0;
  transition: all 0.2s;

  &[data-type="Properties"] {
    left: 130px;
  }
}

.setting-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;

  &.verify {
    grid-template-columns: 1fr;
  }

  &.billing {
    grid-template-columns: 1fr;
  }
}

.test {
  @apply grid p-4;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  }
}

.country-list {
  background-color: var(--bg-sec) !important;
}

.country {
  &:hover {
    > {
      span {
        color: var(--text-main) !important;
      }
    }
  }
}

.highlight {
  background-color: var(--text-main);

  > {
    span {
      color: var(--text-main) !important;
    }
  }
}

.country-name {
  color: var(--title-white) !important;
}

.props-loading-con {
  transition: all 0.3s;
  transition-delay: 0.5s;
  border: 0px;

  &[data-loading="false"] {
    opacity: 0;
    visibility: hidden;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 58px;
  height: 58px;
  margin: 8px;
  border: 6px solid var(--text-main);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--text-main) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.btn-upload-con {
  position: fixed;
  right: 1.25rem;
  bottom: 1.25rem;
  width: 55px;
  height: 55px;
  z-index: 500;
  &.uploading {
    bottom: calc(3rem + 30px);
  }
  @media (max-width: 768px) {
    bottom: calc(1rem + 66px);
  }
}

.btn-upload {
  @apply bg-blue absolute z-[800] grid aspect-square w-[55px] cursor-pointer place-items-center rounded-full bg-gradient-to-r from-sky-500 to-indigo-500 dark:from-sky-800 dark:to-indigo-800;
  bottom: 1.25rem;
  transition: all 0.2s;
  left: 0;
  top: 0;
  transform-origin: center;
  transform: scale(1);
  &[data-hidden="true"] {
    transform: scale(0);
  }

  &[data-bottom="true"] {
    bottom: 6.5rem;
  }

  &.uploading {
    display: none;
  }
}

.btn-gradient {
  overflow: hidden;
  &:after {
    position: absolute;
    content: "";
    display: inline-block;
    background: rgba(59, 173, 227, 1);
    background: linear-gradient(45deg, #1e33f0 0%, #1e33f0 25%, #6f57e9 75%, #6f57e9 100%);
    height: 50px;
    width: 400px;
    z-index: -1;
    left: 0;
    transform: translateX(-150px);
    transition: transform 400ms ease-in;
  }
  &:hover {
    &:after {
      transform: translateX(-100px);
    }
  }
}

.pod-con-outer {
  height: 80px;
  // animation: slideIn 0.35s forwards;
  overflow: hidden;

  &.remove {
    height: 0;
    transition: all 0.2s 0.1s;

    > {
      div {
        transform: translateY(-15px);
        opacity: 0;
        visibility: hidden;
        transition: all 0.2s;
        margin-top: 0;
      }
    }
  }
}

.btn-add-inner {
  position: absolute;
  right: 1px;
  bottom: 1px;

  &[data-bottom="true"] {
    bottom: 6.5rem;
  }

  &[data-hidden="true"] {
    transform: scale(0);
  }

  &.uploading {
    display: none;
  }

  width: 53px;
  max-width: 53px;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  transition: all 0.2s;
  overflow: hidden;

  &[data-show="true"] {
    &:nth-child(2) {
      bottom: calc(3.75rem);

      &[data-bottom="true"] {
        bottom: calc(6.5rem + 65px);
      }
    }

    bottom: calc(1.25rem + 65px);
  }
}

.tips-con {
  overflow: hidden;
  animation: fade-Out 0.35s forwards;
  animation-delay: 5s;

  > {
    div {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px;
      background-color: var(--text-main);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      &[data-loading="false"] {
        animation: countDown 5s linear forwards;
      }
    }
  }
}

.left-con {
  width: 60px;
  padding-left: 1rem;
  transition: all 0.3s;

  &[data-show="true"] {
    width: 206px;
    padding-left: 2.5rem;
  }

  // &:hover {
  //   width: 215px;
  //   padding-left: 1.75rem;
  // }
}

.btn-expand {
  display: grid;
}

@media (min-width: 768px) {
  .btn-add-inner {
    right: 1px;
  }
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }

  .left-con {
    position: fixed;
    left: -64px;
    width: 64px;
    padding-left: 1rem;
    transition: all 0.2s;
    min-height: 100%;
    background-color: var(--bg-sec);

    &[data-show="true"] {
      left: 0;
      width: 240px;
      padding-left: 2.5rem;
    }

    z-index: 999;
  }

  .btn-expand {
    outline: none;
    display: grid;
    position: fixed;
    bottom: inherit;
    top: 14px;
    left: -20px;
    width: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: var(--text-main);
    transition: all 0.2s;
    place-items: center;
    z-index: 400;

    > {
      svg {
        transform: rotate(0deg);
      }
    }

    &[data-show="true"] {
      left: 218px;

      > {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .sec {
    width: 170px;
    padding-left: 50px;
    transition: all 0.2s;

    &:focus {
      width: calc(100vw - 62px);
    }
  }

  .create-bucket-con {
    &.upload,
    &.topup,
    &.create {
      min-width: auto;
      width: 100vw;
      height: fit-content;
      transform: none;
      top: inherit;
      left: 0;
      right: 0;
      bottom: -100%;
      transition: all 0.3s;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border {
        left: 0;
        right: 0;
        bottom: 0;
      }
      max-height: 90vh;
      z-index: 999;

      @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
      }
    }

    &[data-show="true"] {
      bottom: 0;
      top: initial;
    }
  }

  .content-con {
    max-width: 100vw;
    margin: 0 auto;
    margin-top: 57px;
    border-top-left-radius: 0;
    border-top: 0;
    border-left: 0;

    padding: {
      top: 1.25rem;
      left: 1.25rem;
      right: 1.25rem;
    }
  }

  .icon-search {
    display: none;

    &.phone {
      display: grid;
      left: 0.3rem;
    }
  }

  .option-con {
    position: fixed;
    // bottom: initial;
    top: initial;
    bottom: calc(64px - 0.5rem);
    left: 50%;
    width: calc(100% - 2rem);
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition: all 0.3s;
    &[data-show="true"] {
      bottom: calc(64px + 1rem);
      opacity: 1;
      pointer-events: all;
      height: fit-content;
      transform: translateX(-50%);
    }
  }
}

@media (min-height: 2025px) {
  .general-setting-con {
    padding-right: 1.5rem;
  }
}

@media (max-width: 765px) {
  .general-setting-con {
    padding-right: 1.5rem;
  }
}

@supports (-webkit-touch-callout: none) {
  .webkit-fill-h {
    max-height: -webkit-fill-available !important;
  }
}

// Keyframes
@keyframes landing-text-fadein {
  0%,
  33%,
  100% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
}

@keyframes fade-Out {
  0% {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  100% {
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes countDown {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes slideIn {
  0% {
    transform: scale(0.985);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes popup-fadein {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, -50%);
  }
}

@keyframes skeleton-fade-in {
  0% {
    opacity: 0;
    transform: translateX(175px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes skeleton-loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@keyframes uploading-item-fadein {
  0% {
    height: 0;
    padding-bottom: 0;
  }
  100% {
    height: 98px;
    padding-bottom: 1rem;
  }
}

@keyframes right-info-fade-in {
  0% {
    width: 0;
  }
  100% {
    width: 345px;
  }
}

@keyframes bblFadInOut {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.option-hover-con {
  animation: option-fade-in 0.25s forwards;
}

@keyframes option-fade-in {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes option-fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.logo-loading {
  animation: logoLoading 2.5s infinite ease-in-out;
}

@keyframes logoLoading {
  // 0% {
  //   transform: scale(0.8) rotateY(0deg);
  // }

  // 100% {
  //   transform: scale(1.5) rotateY(1080deg);
  // }
  0%,
  100% {
    opacity: 0.3;
    transform: translateY(0px);
  }

  50% {
    opacity: 1;
    transform: translateY(-40px);
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1500px 0;
  }

  100% {
    background-position: 1500px 0;
  }
}

@keyframes checkActive {
  0% {
    width: 0rem;
  }

  100% {
    width: 1.25rem;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes upload-item-remove {
  0% {
    opacity: 1;
    max-height: 98px;
    padding-bottom: 1rem;
  }
  100% {
    opacity: 0;
    max-height: 0;
    padding-bottom: 0;
  }
}


video:-webkit-full-page-media {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

video {
  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}
